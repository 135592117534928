<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col class="px-5">
        <v-col cols="12">
          <v-img
            :src="require('../assets/logo.png')"
            class="my-1"
            contain
            height="100"
            elevation="24"
          />
        </v-col>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <h1>OUG Survey Platform</h1>
    </v-row>
  </v-container>
</template>

<script>

export default {};
</script>

<style lang="scss" scoped></style>
